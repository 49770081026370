<template>
  <div>

    <!--     <v-navigation-drawer app v-model="drawerLeft" left>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Craving </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item v-for="(item, i) in navItems" :key="i">
          <v-list-item-icon>
            <v-icon color="pink" v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-navigation-drawer app v-model="drawer" right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Filter </v-list-item-title>
          <v-list-item-subtitle>
            Favorites ({{ filteredMarkers.length }})
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <!--   
        <v-list-item class="mb-4">
          <v-switch
            v-model="onlyOpen"
            inset
            :label="`Vis kun åbne`"
            hide-details
          ></v-switch>
        </v-list-item>
        <v-divider></v-divider> 
      -->
      <v-list dense nav>
        <v-list-item class="mb-4">
          <v-select label="Country" v-model="selectedCountry" item-text="name" item-value="code" :items="countries"
            v-on:change="setResctrictions" hide-details>
          </v-select>
        </v-list-item>
        <v-list-item class="mb-8">
          <v-select label="Filter by category" v-model="selectedCategories" item-text="name" item-value="icon"
            :items="categories" hide-details multiple>
          </v-select>
        </v-list-item>

        <v-list-item v-for="m in filteredMarkers" :key="m.id" @click="openMarker(m)">
          <v-list-item-avatar>
            <v-img :src="m.icon"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ m.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>

      <div class="header d-flex mt-3">
        <GmapAutocomplete ref="autocomplete" placeholder="Next craving spot..." @focus="searchClosed = false"
          @blur="searchClosed = true" @place_changed="setPlace" class="searchbar flex-grow-1 ml-3"
          :class="{closed: searchClosed}" :options="{
            componentRestrictions: {country: [selectedCountry]},
          }"></GmapAutocomplete>
        <v-btn fab dark color="pink" @click.stop="drawer = !drawer" class="ml-1 mr-3">
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>
      </div>
      <!--       
      <v-btn class="mt-12" fab dark color="pink" top left absolute @click.stop="drawerLeft = !drawerLeft">
        <v-icon>mdi-menu</v-icon>
      </v-btn> 
      -->
      <GmapMap ref="mapRef" @click="placeMarker($event)" @dragend="dragEnd" :center="center" :zoom="zoom"
        :options="options" style="width: 100%; height: 100vh">
        <GmapMarker :key="index" v-for="(u, index) in users" :position="u"
          :label="{text: u.name.split(' ')[0], color: 'black'}" :icon="{
            url: `${u.avatar}`,
            scaledSize: {width: 40, height: 40},
            labelOrigin: {x: 20, y: -15},
          }">
        </GmapMarker>
        <GmapCluster :gridSize="50" :zoomOnClick="true" :enableRetinaIcons="true" :minimumClusterSize="100">
          <GmapMarker :key="index" v-for="(m, index) in filteredMarkers" :position="m" :clickable="true"
            @click="openMarker(m)" :icon="{
              url: m.icon,
              scaledSize: {width: 40, height: 40},
            }">
            <GmapInfoWindow :opened="openedMarkerID === m.id"></GmapInfoWindow>
          </GmapMarker>
        </GmapCluster>
      </GmapMap>

      <v-bottom-sheet v-model="sheet">
        <v-card v-if="selectedMarker">
          <v-card-title>
            <span class="text-truncate">{{ selectedMarker.label }}</span>
            <div v-if="getOverallRating(selectedMarker)" class="ml-auto d-flex">
              <v-icon color="pink" class="mr-2">mdi-star</v-icon>
              {{ getOverallRating(selectedMarker) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-5" style="height: 500px; overflow: auto">
            <v-form>
              <v-container fluid>
                <v-row v-if="!editMode">
                  <v-carousel hide-delimiter-background hide-delimiters height="200">
                    <template v-slot:prev="{on, attrs}">
                      <v-icon v-bind="attrs" v-on="on">mdi-menu-left</v-icon>
                    </template>
                    <template v-slot:next="{on, attrs}">
                      <v-icon v-bind="attrs" v-on="on">mdi-menu-right</v-icon>
                    </template>
                    <v-carousel-item v-for="photo in selectedMarker.photos" :key="photo">
                      <v-sheet height="100%" tile>
                        <v-row class="fill-height" align="center" justify="center">
                          <v-img :src="photo" contain max-height="200"></v-img>
                        </v-row>
                      </v-sheet>
                    </v-carousel-item>
                  </v-carousel>
                  <v-col cols="12">
                    <p>{{ selectedMarker.note }}</p>

                    <v-card outlined class="my-8 pa-6">
                      <v-row>
                        <v-col cols="12" sm="6"><label>Food</label>
                          <v-rating class="mb-4 mt-2" background-color="grey lighten-2" color="pink" hover length="6"
                            size="36" dense v-model="selectedMarker.rating" readonly></v-rating>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <label>Atmosphere</label>
                          <v-rating class="mb-4 mt-2" background-color="grey lighten-2" color="pink" hover length="6"
                            size="36" dense v-model="selectedMarker.atmosphere" readonly></v-rating></v-col>
                        <v-col cols="12" sm="6">
                          <label>Service</label>
                          <v-rating class="mb-4 mt-2" background-color="grey lighten-2" color="pink" hover length="6"
                            size="36" dense v-model="selectedMarker.service" readonly></v-rating>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <label>Value for money</label>
                          <v-rating class="mb-4 mt-2" background-color="grey lighten-2" color="pink" hover length="6"
                            size="36" dense v-model="selectedMarker.valueformoney" readonly></v-rating>
                        </v-col>
                      </v-row>
                      <div v-if="selectedMarker.opening_hours">
                        <div class="mt-5 mb-2"><b>Åbningstider</b></div>
                        <div v-for="hour in selectedMarker.opening_hours" :key="hour" class="text-capitalize mb-2">
                          {{ hour }}<br />
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-if="editMode">
                  <v-col cols="12">
                    <v-select label="Category" item-text="name" item-value="icon" v-model="selectedMarker.icon"
                      :items="categories" hide-details>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select label="Country" item-text="name" item-value="code" v-model="selectedMarker.country"
                      :items="countries" hide-details>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="selectedMarker.label" label="Place" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="selectedMarker.note" label="Note" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="selectedMarker.instagram" label="Instagram" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Food</label>
                    <v-rating class="mb-4 mt-2" background-color="grey lighten-2" color="pink" hover length="6" size="36"
                      dense v-model="selectedMarker.rating"></v-rating>

                    <label>Atmosphere</label>
                    <v-rating class="mb-4 mt-2" background-color="grey lighten-2" color="pink" hover length="6" size="36"
                      dense v-model="selectedMarker.atmosphere"></v-rating>

                    <label>Service</label>
                    <v-rating class="mb-4 mt-2" background-color="grey lighten-2" color="pink" hover length="6" size="36"
                      dense v-model="selectedMarker.service"></v-rating>

                    <label>Value for money</label>
                    <v-rating class="mb-4 mt-2" background-color="grey lighten-2" color="pink" hover length="6" size="36"
                      dense v-model="selectedMarker.valueformoney"></v-rating>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div v-if="!editMode" class="d-flex justify-space-between" style="width: 100%">
              <v-btn depressed dark color="pink"
                :href="`https://www.google.com/maps/search/?api=1&query=${selectedMarker.lat}%2C${selectedMarker.lng}&query_place_id=${selectedMarker.place_id}`">
                <v-icon dark>mdi-directions</v-icon>
              </v-btn>
              <v-btn v-if="selectedMarker.instagram" depressed dark color="brown" :href="selectedMarker.instagram">
                <v-icon dark>mdi-instagram</v-icon>
              </v-btn>
              <v-btn depressed color="grey lighten-2" @click="editMarker()" :disabled="!currentUser.admin">
                Edit
              </v-btn>
            </div>
            <div v-if="editMode" class="d-flex justify-space-between" style="width: 100%">
              <v-btn depressed @click="removeMarker(selectedMarker)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              <v-btn dark class="ml-auto" depressed color="pink" @click="saveMarker(selectedMarker)">
                Save
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-bottom-sheet>
    </v-main>
  </div>
</template>

<script>
import db from "@/fb";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

import {getAuth} from "firebase/auth";

import {gmapApi} from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

export default {
  name: "MapOverview",
  components: {GmapCluster},

  data() {
    return {
      currentUser: null,
      navItems: [
        {text: "Map", icon: "mdi-google-maps"},
        {text: "Find Cravers", icon: "mdi-account-plus"},
        {text: "My Profil", icon: "mdi-account"},
        {text: "Logout", icon: "mdi-power"},
      ],
      searchClosed: true,
      geocoder: null,
      options: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        gestureHandling: "greedy",
        clickableIcons: false,
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
      editMode: false,
      drawerLeft: false,
      drawer: false,
      sheet: false,
      openedMarkerID: null,
      center: {lat: 55.67552072579835, lng: 12.516354311857109},
      zoom: localStorage.lastZoom ? Number(localStorage.lastZoom) : 13,
      markers: [],
      users: [],
      checkedPlaces: [],
      filter: {
        category: null,
      },
      selectedCountry: localStorage.selectedCountry ?? "dk",
      countries: [
        {name: "All", code: "*"},
        {name: "Denmark", code: "dk"},
        {name: "Germany", code: "de"},
        {name: "France", code: "fr"},
        {name: "Norway", code: "no"},
        {name: "Sweden", code: "se"},
        {name: "England", code: "gb"},
        {name: "Vietnam", code: "vn"},
      ],
      onlyOpen: false,
      selectedCategories: [],
      categories: [
        {
          name: "Coffee",
          icon: "icons/icons8-takeaway-hot-drink-64.png",
        },
        {
          name: "Fastfood",
          icon: "icons/icons8-hamburger-64.png",
        },
        {
          name: "Dessert",
          icon: "icons/icons8-cupcake-64.png",
        },
        {
          name: "Sushi",
          icon: "icons/icons8-sushi-64.png",
        },
        {
          name: "Noodles",
          icon: "icons/icons8-noodles-64.png",
        },
        {
          name: "Bread",
          icon: "icons/icons8-bread-and-rolling-pin-64.png",
        },
        {
          name: "Crossaint",
          icon: "icons/icons8-croissant-64.png",
        },
        {
          name: "Seafood",
          icon: "icons/icons8-seafood-64.png",
        },
        {
          name: "Fine dining",
          icon: "icons/icons8-food-and-wine-64.png",
        },
        {
          name: "Bubbletea",
          icon: "icons/icons8-kawaii-soda-64.png",
        },
        {
          name: "Steak",
          icon: "icons/icons8-kawaii-steak-64.png",
        },
        {
          name: "Pizza",
          icon: "icons/icons8-salami-pizza-64.png",
        },
        {
          name: "Breakfast/Lunch",
          icon: "icons/icons8-sandwich-with-fried-egg-64.png",
        },
        {
          name: "Pasta",
          icon: "icons/icons8-spaghetti-64.png",
        },
        {
          name: "Dim Sum",
          icon: "icons/icons8-dumplings-64.png",
        },
        {
          name: "Rice",
          icon: "icons/icons8-rice-bowl-64.png",
        },
        {
          name: "Ice cream",
          icon: "icons/icons8-ice-cream-pink-cone-64.png",
        },
        {
          name: "Taco",
          icon: "icons/icons8-taco-64.png",
        },
        {
          name: "BBQ",
          icon: "icons/icons8-grill-64.png",
        },
        {
          name: "Kebab",
          icon: "icons/icons8-fastfood-64.png",
        },
        {
          name: "Nordic",
          icon: "icons/icons8-snowflake-64.png",
        },
        {
          name: "France",
          icon: "icons/icons8-france-64.png",
        },
        {
          name: "Latin American",
          icon: "icons/icons8-alpaca-64.png",
        },
        {
          name: "Middle Eastern",
          icon: "icons/icons8-united-arab-emirates-64.png",
        },
        {
          name: "Italian",
          icon: "icons/icons8-italy-64.png",
        },
        {
          name: "Vietnamese",
          icon: "icons/icons8-vietnam-64.png",
        },
        {
          name: "Chinese",
          icon: "icons/icons8-china-64.png",
        },
        {
          name: "Indian",
          icon: "icons/icons8-india-64.png",
        },
      ],
      selectedMarker: null,
    };
  },

  async mounted() {
    this.geolocate();

    // Set checked places
    if (localStorage.checkedPlaces) {
      this.checkedPlaces = JSON.parse(localStorage.checkedPlaces);
    }

    // Get active users
    const queryUsersSnapshot = await getDocs(collection(db, "users"));
    queryUsersSnapshot.forEach((user) => {
      this.users.push(user.data());
    });

    // Refactor this!
    const auth = getAuth();
    const user = auth.currentUser;
    this.currentUser = user;
    this.currentUser.admin = this.users.find((x) => x.uid == user.uid).admin;

    const querySnapshot = await getDocs(collection(db, "places"));
    querySnapshot.forEach((doc) => {
      this.markers.push({id: doc.id, ...doc.data()});
    });

    // Sort places by name
    this.markers.sort((a, b) => a.label.localeCompare(b.label));
  },

  computed: {
    google: gmapApi,
    filteredMarkers() {

      // Save country in local storage
      localStorage.selectedCountry = this.selectedCountry;

      let tmpMarkers = this.markers;

      if (this.selectedCountry !== "*") {
        tmpMarkers = tmpMarkers.filter(
          (x) => x.country == this.selectedCountry
        );
      }

      if (this.selectedCategories.length > 0) {
        tmpMarkers = tmpMarkers.filter((x) =>
          this.selectedCategories.includes(x.icon)
        );
      }

      /*  
        if (this.selectedCategories.length > 0 && this.onlyOpen) {
          tmpMarkers = tmpMarkers.filter((marker) => {
            return false;
          });

            
            this.markers = this.markers.map((marker) => {
              var request = {
                placeId: marker.place_id,
                fields: ["opening_hours"],
              };

              const map = await this.$refs.mapRef.$mapPromise;
              const service = new this.google.maps.places.PlacesService(map);

              service.getDetails(request, (result) => {
                marker.isOpen = result.opening_hours.open_now;
              });

              return marker;
            }); 
          
        }
      */

      return tmpMarkers;
    },
  },

  methods: {
    getOverallRating(marker) {
      const maxRating = 24;
      const avgRating =
        ((marker.atmosphere +
          marker.rating +
          marker.service +
          marker.valueformoney) /
          maxRating) *
        6;
      return avgRating;
    },
    setResctrictions(country) {
      this.$refs.autocomplete.$autocomplete.componentRestrictions.country = [
        country,
      ];
    },
    goToCurrentPosition() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    async setPlace(place) {
      var foundPlace = this.markers.find((x) => {
        return x.place_id === place.place_id;
      });

      if (foundPlace) {
        this.openMarker(foundPlace);

        // Clear search field
        this.$refs.autocomplete.$el.value = null;
        return;
      }

      // Only admin can create new places
      if (!this.currentUser.admin) return;

      // Create new marker
      const newMarker = {
        place_id: place.place_id,
        label: place.name,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        icon: place.icon,
        country: this.selectedCountry,
        rating: null,
        note: null,
      };

      if (place.photos) {
        newMarker.photos = place.photos.map((x) =>
          x.getUrl({maxHeight: 300})
        );
      }

      if (place.opening_hours) {
        newMarker.opening_hours = place.opening_hours?.weekday_text;
      }

      if (
        newMarker.icon ==
        "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/restaurant-71.png"
      ) {
        newMarker.icon = "icons/icons8-cupcake-64.png";
      }

      // Add to Firestore
      try {
        const docRef = await addDoc(collection(db, "places"), newMarker);
        newMarker.id = docRef.id;

        // Place marker
        this.markers.push(newMarker);

        // Center map
        this.center = {lat: newMarker.lat, lng: newMarker.lng};

        // Open marker
        this.openedMarkerID = newMarker.id;

        // Clear search field
        this.$refs.autocomplete.$el.value = null;
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },
    placeMarker() {
      this.openedMarkerID = null;
      this.drawer = false;
      this.$refs.autocomplete.$el.blur();
    },
    async dragEnd() {
      const map = await this.$refs.mapRef.$mapPromise;
      localStorage.lastPosition = JSON.stringify({lat: map.center.lat(), lng: map.center.lng()});
      localStorage.lastZoom = map.zoom;
    },
    geolocate() {
      // Update current position
      navigator.geolocation.watchPosition((position) => {
        const auth = getAuth();
        const user = auth.currentUser;
        const userRef = doc(db, "users", user.uid);
        setDoc(
          userRef,
          {
            uid: user.uid,
            name: user.displayName,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            lastSeen: new Date().toISOString(),
          },
          {merge: true}
        );
      });

      // Set init position
      if (localStorage.lastPosition) {
        this.center = JSON.parse(localStorage.lastPosition);
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      }

    },
    async openMarker(marker) {
      // Exit editmode
      this.editMode = false;

      if (marker) {
        // Get Photos

        const map = await this.$refs.mapRef.$mapPromise;

        var request = {
          placeId: marker.place_id,
          fields: ["name", "photos", "opening_hours"],
        };

        const service = new this.google.maps.places.PlacesService(map);
        service.getDetails(request, (result) => {
          // get latest opening hours
          marker.opening_hours = result.opening_hours?.weekday_text;

          // get latest photos
          marker.photos = result.photos.map((x) =>
            x.getUrl({maxHeight: 300})
          );

          marker.label = marker.label + " ";
        });

        // Open bottom sheet
        this.sheet = true;
        this.selectedMarker = marker;

        this.openedMarkerID = marker.id;

        // Close drawer
        this.drawer = false;
      }
    },
    editMarker() {
      // Exit editmode
      this.editMode = true;
    },
    async removeMarker(marker) {
      await deleteDoc(doc(db, "places", marker.id));

      this.markers.splice(this.markers.indexOf(marker), 1);
    },
    async saveMarker(marker) {
      // Update
      await setDoc(doc(db, "places", marker.id), marker, {
        merge: true,
      });

      // Exit editmode
      this.editMode = false;
    },
    clearMarkers() {
      this.markers = [];

      // Close drawer
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  z-index: 1;
  width: 100%;
}

.searchbar {
  z-index: 4;
  background: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  padding: 14px 20px;
  outline: none;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
  border-radius: 30px;
}

.avatar_name {
  background-color: #000;
}
</style>
