// Initialize Cloud Firestore through Firebase
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
initializeApp({
  apiKey: "AIzaSyAzX5EZl_unvzBimwBO9h3-JQ38-v0X6hc",
  authDomain: "craving-339012.firebaseapp.com",
  projectId: "craving-339012",
});

const db = getFirestore();

export default db;
