<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-btn class="ma-2" color="pink" dark x-large @click.stop="googleSignIn()"
        >Sign in with google</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import { getAuth, signInWithRedirect, GoogleAuthProvider } from "firebase/auth";
export default {
  name: "Login",
  methods: {
    googleSignIn() {
      const provider = new GoogleAuthProvider();
      window.location.hash = "map";
      const auth = getAuth();
      signInWithRedirect(auth, provider);
    },
  },
};
</script>

<style></style>
